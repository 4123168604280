import React from 'react'
import Checkout from './Checkout'
import PropTypes from 'prop-types'

const ProductPage = ({
  name,
  imgSrc,
  isMarketSpot,
  price,
}) => (
  <div className="product-page">
    <div className="product-page__left">
      <div className="product-page__image__wrapper">
        <img className="product-page__image" src={imgSrc} alt={name} />
      </div>
    </div>
    <div className="product-page__right">
      <div className="purchase-product">
        <Checkout
          className="purchase-product__checkout"
          isMarketSpot={isMarketSpot}
          productName={name}
          price={price}
        />
      </div>
    </div>
  </div>
)

ProductPage.propTypes = {
  closeNotification: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
}

export default ProductPage
