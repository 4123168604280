import React from 'react'
import PropTypes from 'prop-types'

const Product = ({
  name,
  imgSrc,
  openProductPage,
  price,
}) => (
  <div className="product" onClick={openProductPage}>
    <div className="product__image__wrapper">
      <img className="product__image" src={imgSrc} alt={name} />
    </div>
    <div className="product__details__wrapper">
      <p className="product__name">{name}</p>
      <p className="product__price">${price}</p>
      <span className="product__buy">Buy</span>
    </div>
  </div>
)

Product.propTypes = {
  name: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  openProductPage: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
}

export default Product
