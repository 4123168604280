import React from "react";
// import { Link } from 'react-router-dom'

import cx from "classnames";
import Product from "./Product";
import ProductPage from "./ProductPage";

import ankleSocks from "../assets/icons/TP_Icon_AnkleSocks.png";
import bottleOpener from "../assets/icons/TP_Icon_BottleOpener.png";
import fullLengthSocks from "../assets/icons/TP_Icon_FLSocks.png";
import shirt from "../assets/icons/TP_Icon_Shirt.png";
import tote from "../assets/icons/TP_Icon_Tote.png";
import stressBall from "../assets/icons/TP_Icon_StressBall.png";
import weightedEyeMask from "../assets/icons/TP_Icon_EyeMask.png";
import blueLightGlasses from "../assets/icons/TP_Icon_BlueLightGlasses.png";
import smallBottleOpener from "../assets/icons/TP_Icon_BottleOpener2.png";
import portableCharger from "../assets/icons/TP_Icon_PortableCharger.png";
import ballCap from "../assets/icons/TP_Icon_BallCap.png";
import eyeMask2 from "../assets/icons/TP_Icon_EyeMask2.png";
import hotColdEyeMask from "../assets/icons/TP_Icon_HotColdEyeMask.png";
import koozie from "../assets/icons/TP_Icon_Koozie.png";
import airPods from "../assets/icons/Airpods.png";
import giftCard from "../assets/icons/Gift_Card.png";

// import logo from '../logo.png'

export const PRODUCT_DETAILS = {
  // shirt: {
  //   id: 1,
  //   name: 'Shirt',
  //   price: 10,
  //   imgSrc: shirt,
  //   isMarketSpot: false,
  //   description: null,
  // },
  // 'ankle socks': {
  //   id: 2,
  //   name: 'Ankle Socks',
  //   price: 10,
  //   imgSrc: ankleSocks,
  //   isMarketSpot: false,
  //   description: null,
  // },
  // 'full length socks': {
  //   id: 3,
  //   name: 'Full-length Socks',
  //   price: 10,
  //   imgSrc: fullLengthSocks,
  //   isMarketSpot: false,
  //   description: null,
  // },
  // 'ball cap': {
  //   id: 11,
  //   name: 'Ball Cap',
  //   price: 10,
  //   imgSrc: ballCap,
  //   isMarketSpot: false,
  //   description: null,
  // },
  // 'portable charger': {
  //   id: 17,
  //   name: 'Portable Charger',
  //   price: 10,
  //   imgSrc: portableCharger,
  //   isMarketSpot: false,
  //   description: null,
  // },
  // tote: {
  //   id: 4,
  //   name: 'Tote',
  //   price: 10,
  //   imgSrc: tote,
  //   isMarketSpot: false,
  //   description: null,
  // },
  // 'bottle opener': {
  //   id: 5,
  //   name: 'Bottle Opener',
  //   price: 10,
  //   imgSrc: bottleOpener,
  //   isMarketSpot: false,
  //   description: null,
  // },
  // 'stress ball': {
  //   id: 6,
  //   name: 'Stress Ball',
  //   price: 10,
  //   imgSrc: stressBall,
  //   isMarketSpot: false,
  //   description: null,
  // },
  // 'weighted eye mask': {
  //   id: 7,
  //   name: 'Weighted Eye Mask',
  //   price: 10,
  //   imgSrc: weightedEyeMask,
  //   isMarketSpot: false,
  //   description: null,
  // },
  // 'blue light glasses': {
  //   id: 8,
  //   name: 'Blue Light Glasses',
  //   price: 10,
  //   imgSrc: blueLightGlasses,
  //   isMarketSpot: false,
  //   description: null,
  // },
  // 'small bottle opener': {
  //   id: 9,
  //   name: 'Small Bottle Opener',
  //   price: 10,
  //   imgSrc: smallBottleOpener,
  //   isMarketSpot: false,
  //   description: null,
  // },
  // 'eye mask': {
  //   id: 10,
  //   name: 'Eye Mask',
  //   price: 10,
  //   imgSrc: eyeMask2,
  //   isMarketSpot: false,
  //   description: null,
  // },
  // 'hot cold eye mask': {
  //   id: 11,
  //   name: 'Hot/Cold Eye Mask',
  //   price: 10,
  //   imgSrc: hotColdEyeMask,
  //   isMarketSpot: false,
  //   description: null,
  // },
  // koozie: {
  //   id: 12,
  //   name: 'Koozie',
  //   price: 10,
  //   imgSrc: koozie,
  //   isMarketSpot: false,
  //   description: null,
  // },
  giftCard: {
    id: 13,
    name: "Gift Card",
    price: 10,
    imgSrc: giftCard,
    isMarketSpot: false,
    description: null,
  },
  airPods: {
    id: 14,
    name: "Air Pods",
    price: 10,
    imgSrc: airPods,
    isMarketSpot: false,
    description: null,
  },
};

class Marketplace extends React.PureComponent {
  constructor(props) {
    super(props);

    this.closeProductPage = this.closeProductPage.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.openProductPage = this.openProductPage.bind(this);
    this.toggleNotification = this.toggleNotification.bind(this);

    this.state = {
      activeProduct: {},
      isProductPageOpen: false,
      showNotification: true,
    };
  }

  openProductPage(product) {
    this.setState({
      activeProduct: product,
      isProductPageOpen: true,
      showNotification: true,
    });
  }

  closeProductPage() {
    this.setState({
      activeProduct: {},
      isProductPageOpen: false,
      showNotification: true,
    });
  }

  closeNotification() {
    this.setState({ showNotification: false });
  }

  toggleNotification() {
    this.setState((prevState) => ({
      showNotification: !prevState.showNotification,
    }));
  }

  render() {
    const { activeProduct, isProductPageOpen, showNotification } = this.state;
    const { name, imgSrc, price } = activeProduct;

    return (
      <div className="marketplace">
        <div className="marketplace__header">
          {/* <img src={logo} className="marketplace__logo" alt="logo" onClick={this.closeProductPage} /> */}
          <div className="marketplace__bestsellers">
            <span className="marketplace__bestsellers__blue">Marketplace</span>{" "}
            Bestsellers
          </div>
        </div>
        <div
          className={cx("marketplace__content", {
            "product-page__open": isProductPageOpen,
          })}
        >
          <div className="marketplace__header__buffer">
            {isProductPageOpen ? (
              <div
                className="back-to-marketplace"
                onClick={this.closeProductPage}
              >
                <span className="marketplace__content__product-page__back">
                  {"< Back To All Products"}
                </span>
              </div>
            ) : (
              <div></div>
            )}{" "}
            {/* Removed link back from general ledger for Netsuite event */}
            {/* (
              <Link className="back-to-marketplace" to="/accountledger">
                <span className="marketplace__content__product-page__back">{'< View Account Ledger'}</span>
              </Link>
            ) */}
          </div>
          {isProductPageOpen ? (
            <>
              <div className="marketplace__content__product-page__wrapper">
                <ProductPage
                  closeNotification={this.closeNotification}
                  name={name}
                  imgSrc={imgSrc}
                  price={price}
                />
              </div>
            </>
          ) : (
            <>
              <div className="marketplace__content__grid__wrapper">
                <div className="marketplace__content__grid">
                  {Object.values(PRODUCT_DETAILS).map((product) => (
                    <Product
                      key={product.id}
                      name={product.name}
                      price={product.price}
                      imgSrc={product.imgSrc}
                      openProductPage={() => this.openProductPage(product)}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className={cx("guidance-notification", {
            "hide-notification": !showNotification,
          })}
          onClick={this.toggleNotification}
        >
          {isProductPageOpen
            ? "Create a request in Slack to obtain your credit card information."
            : "Welcome to Teampay Marketplace! Select a product to purchase."}
        </div>
      </div>
    );
  }
}

export default Marketplace;
