import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'

const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const YEARS = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2099]

class Checkout extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      cardNumber: '',
      cvv: '',
      email: '',
      name: '',
      orderNumber: Math.floor(Math.random() * 1000000000),
      isConfirmation: false,
      isLoading: false,
      isPaymentFormError: false,
      isSubmitError: false,
      monthExp: '11',
      shirtSize: 'Shirt, Size XS',
      yearExp: '2024',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.verifyCheckout = this.verifyCheckout.bind(this)
    this.handleSizeChange = this.handleSizeChange.bind(this)
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value })
  }

  handleSizeChange(event) {
    this.setState({ shirtSize: event.target.value })
  }

  handleSubmit(e) {
    // checks to see if required fields are filled out
    if (this.verifyCheckout()) {
      this.setState({
        isLoading: true,
        isPaymentFormError: false,
      })
      this.submitPayment()
    } else {
      this.setState({
        isPaymentFormError: true,
      })
    }
    e.preventDefault()
  }

  submitPayment() {
    const {
      cardNumber,
      email,
      name,
      orderNumber,
      shirtSize,
    } = this.state
    const {
      price,
      productName,
      closeNotification,
      isMarketSpot,
    } = this.props

    const firstName = name.split(' ')[0]

    const paymentInformation = {
      email,
      name: firstName,
      isMarketSpot,
      pan: cardNumber,
      receipt: {
        name,
        orderNumber,
        pan: cardNumber,
        price,
        productName: productName === 'Shirt' ? shirtSize : productName,
      },
    }

    fetch('/checkout/process', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(paymentInformation),
    })
      .then(async response => {
        if (response.status === 200) {
          this.setState({
            isConfirmation: true,
            isLoading: false,
            isSubmitError: false,
          }, closeNotification)
        } else {
          this.setState({
            isLoading: false,
            isSubmitError: true,
          })
        }
      })
  }

  verifyCheckout() {
    const {
      cardNumber,
      monthExp,
      name,
      yearExp,
    } = this.state
    const mandatory = [cardNumber, monthExp, name, yearExp]

    return mandatory.every(field => field.length > 0)
  }

  renderPrice() {
    const { isMarketSpot, price, productName } = this.props
    if (isMarketSpot) {
      if (productName === 'Monthly') return `${price} / month`
      if (productName === 'Annual') return `${price} / year`
      return `${price} billed once`
    }
    return price
  }

  render() {
    const {
      cardNumber,
      cvv,
      email,
      isConfirmation,
      isLoading,
      isPaymentFormError,
      isSubmitError,
      monthExp,
      name,
      orderNumber,
      shirtSize,
      yearExp,
    } = this.state

    const {
      className,
      isMarketSpot,
      productName,
      price,
    } = this.props

    return (
      <div className={cx(`checkout ${className}`, { error: isPaymentFormError })}>
        <div className="checkout__input__wrapper">
          {isConfirmation
            ? (
              <>
                <h2 className="product-page__receipt__header">Order confirmed!</h2>
                <div>
                  <p className="product-page__receipt__order-number">Order number {orderNumber}</p>
                  {(!!email && !isMarketSpot) && <p className="product-page__receipt product-page__receipt__header">A confirmation email will be sent to {email}</p>}
                  <p className="product-page__receipt">Date: {moment().format('MM-DD-YYYY')}</p>
                  <p className="product-page__receipt">Item: {productName === 'Shirt' ? shirtSize : productName}</p>
                  <p className="product-page__receipt">Price: ${price}</p>
                  <p className="product-page__receipt">Name: {name}</p>
                  <p className="product-page__receipt">Credit card ending in {cardNumber.slice(cardNumber.length - 4)}</p>
                  {isMarketSpot
                    ? null
                    : (
                      <Link to="/accountledger">
                        <span className="submit primary product-page__view-transaction">View transaction</span>
                      </Link>
                    )}
                </div>
              </>
            )
            : (
              <>
                <h2 className="product-page__name">{productName}</h2>
                <p className="product-page__price">${this.renderPrice()}</p>
                {productName === 'Shirt' && (
                  <div className="product-page__size">
                    <span className="product-page__size__label">Select a size: </span>
                    <select className="product-page__size__select" onChange={this.handleSizeChange}>
                      <option value="Shirt, Size XS">XS</option>
                      <option value="Shirt, Size S">S</option>
                      <option value="Shirt, Size M">M</option>
                      <option value="Shirt, Size L">L</option>
                      <option value="Shirt, Size XL">XL</option>
                    </select>
                  </div>
                )}
                <form
                  className="product-page__form"
                  onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                  onSubmit={this.handleSubmit}
                >
                  <label>
                    Your Name*
                    <input
                      className="checkout__input"
                      id="name"
                      value={name}
                      onChange={this.handleChange}
                    />
                  </label>
                  <label>
                    Your Email
                    <input
                      className="checkout__input"
                      id="email"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </label>
                  <label>
                    Card number*
                    <input
                      className="checkout__input"
                      id="cardNumber"
                      value={cardNumber}
                      onChange={this.handleChange}
                    />
                  </label>
                  <label className="checkout__input__small">
                    <span className="checkout__input__small__exp">Expiry date*</span>
                    <span className="checkout__input__small__cvv">CVV*</span>
                    <select value={monthExp} id="monthExp" className='monthExp' onChange={this.handleChange}>
                      <option value=''>MM</option>
                      {MONTHS.map(month => <option value={month}>{month}</option>)}
                    </select>
                    <select value={yearExp} id="yearExp" className='yearExp' onChange={this.handleChange}>
                      <option value=''>YYYY</option>
                      {YEARS.map(year => <option value={year}>{year}</option>)}
                    </select>
                    <input
                      className="checkout__input__cvv"
                      id="cvv"
                      placeholder="000"
                      value={cvv}
                      onChange={this.handleChange}
                    />
                  </label>
                  <input
                    className="submit primary"
                    type="submit"
                    value={isLoading ? 'Processing...' : 'Buy'}
                    disabled={isLoading}
                  />
                  <a
                    href="https://www.teampay.co/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="checkout__privacy-policy">Privacy Policy</span>
                  </a>
                  <span className={cx('checkout__error-message', { error: isPaymentFormError || isSubmitError })}>
                    {isSubmitError ? 'Oops! A payment error has occured.' : '*Please complete all required fields'}
                  </span>
                </form>
              </>
            )}
        </div>
      </div>
    )
  }
}

Checkout.propTypes = {
  className: PropTypes.string,
  productName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
}

Checkout.defaultProps = {
  className: '',
}

export default Checkout
