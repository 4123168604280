import React, { useState, useEffect } from 'react'

const PurchaseConfirmationEmailEditor = () => {
    const [greeting, setGreeting] = useState('')
    const [message, setMessage] = useState('')
    const [key, setKey] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            fetch("/email/latest-confirmation-email")
                .then(response => response.json())
                .then(emailData => {
                    setGreeting(emailData.greeting)
                    setMessage(emailData.message)
                })
        }
        fetchData()
    }, [])

    const handleSubmit = e => {
        e.preventDefault()
        fetch('/email/set-confirmation-email', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ greeting, message, key }),
        }).then(response => {
            if (response.status === 200) alert('Successfully updated confirmation email!')
            else alert('Not authorized!')
        })
    }

    return (
        <div className="email-editor__wrapper">
            <form className="email-editor__form" onSubmit={handleSubmit}>
                <label>Greeting</label>
                <textarea
                    className="email-editor__greeting"
                    value={greeting}
                    onChange={e => setGreeting(e.target.value)}
                />
                <label>Message</label>
                <textarea
                    className="email-editor__message"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                />
                <label>Key</label>
                <input className="email-editor__key-input" type="password" value={key} onChange={e => setKey(e.target.value)} />
                <input type="submit" value="Submit" />
            </form>
        </div>
    )
}

export default PurchaseConfirmationEmailEditor