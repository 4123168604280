import React from 'react'
import PropTypes from 'prop-types'

const ProductPlan = ({
  descriptions,
  details,
  name,
  imgSrc,
  openProductPage,
  price,
}) => (
  <div className="product-plan" onClick={openProductPage}>
    <div className="product-plan__header">
      <div className="product-plan__image__wrapper">
        <img className="product__image" src={imgSrc} alt={name} />
      </div>
    </div>
    <div className="product-plan__descriptions">
      {descriptions.map(description => (
        <div className="product-plan__description__wrapper">
          <svg
            className="product-plan__description__icon"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 24 24"
          >
            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/>
          </svg>
          <p className="product-plan__description">{description}</p>
        </div>
      ))}
    </div>
    <div className="product-plan__buy">Buy now</div>
    <div className="product-plan__details__wrapper">
      <p className="product-plan__details__price">${price}</p>
      <p className="product-plan__details">{details}</p>
    </div>
  </div>
)

ProductPlan.propTypes = {
  descriptions: PropTypes.array.isRequired,
  details: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  openProductPage: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
}

export default ProductPlan
