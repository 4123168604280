import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

const Transaction = ({
  amount,
  category,
  date,
  description,
  name,
}) => (
  <div className="transaction">
    <div className="transaction__date">
      <span>{moment(date, 'YYYY-MM-DD').format('MM-DD-YYYY')}</span>
    </div>
    <div className="transaction__text">
      <span>{name}</span>
    </div>
    <div className="transaction__text">
      <span>Credit card</span>
    </div>
    <div className="transaction__text">
      <span>{description}</span>
    </div>
    <div className="transaction__text">
      <span>{category}</span>
    </div>
    <div className="transaction__amount">
      <span>{amount}</span>
    </div>
  </div>
)

Transaction.propTypes = {
  amount: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default Transaction
