import React, { useState } from 'react'

import cx from 'classnames'
import ProductPlan from './ProductPlan'
import ProductPage from './ProductPage'

import annual from '../assets/icons/TP_Icon_Annual.png'
import monthly from '../assets/icons/TP_Icon_Monthly.png'
import single from '../assets/icons/TP_Icon_Single.png'

import marketspotLogo from '../assets/logos/TP_MarkspotV2.png'

export const PRODUCT_DETAILS = {
  STARTER: {
    details: 'billed once - 4 week trial',
    id: 7,
    name: 'Single',
    price: 75,
    imgSrc: single,
    isMarketSpot: true,
    descriptions: ['500 contacts', 'Intelligent nurture campaigns', 'Lead & account database'],
  },
  PREMIUM: {
    details: 'billed monthly',
    id: 8,
    name: 'Monthly',
    price: 100,
    imgSrc: monthly,
    isMarketSpot: true,
    descriptions: ['Includes Single capabilities +', '2,000 contacts', 'Sandbox environment'],
  },
  PROFESSIONAL: {
    details: 'billed annually',
    id: 9,
    name: 'Annual',
    price: 650,
    imgSrc: annual,
    isMarketSpot: true,
    descriptions: ['Includes Single capabilities +', '2,000 contacts', 'Sandbox environment'],
  },
}

const MarketSpot = () => {
  const [activeProduct, setActiveProduct] = useState({})
  const [isProductPageOpen, setIsProductPageOpen] = useState(false)

  const openProductPage = product => {
    setActiveProduct(product)
    setIsProductPageOpen(true)
  }

  const closeProductPage = () => {
    setActiveProduct({})
    setIsProductPageOpen(false)
  }

  const {
    name,
    imgSrc,
    isMarketSpot,
    price,
  } = activeProduct

  return (
    <div className="marketplace">
      <div className="marketplace__header">
        <img src={marketspotLogo} className="marketplace__logo" alt="logo" onClick={closeProductPage} />
      </div>
      <div className={cx('marketplace__content', { 'product-page__open': isProductPageOpen })}>
        <div className="marketplace__header__buffer">
          {isProductPageOpen ? (
            <div className="back-to-marketplace" onClick={closeProductPage}>
              <span className="marketplace__content__product-page__back">{'< Back To All Products'}</span>
            </div>
          ) : null}
        </div>
        {isProductPageOpen ? (
          <>
            <div className="marketplace__content__product-page__wrapper">
              <ProductPage
                name={name}
                imgSrc={imgSrc}
                isMarketSpot={isMarketSpot}
                price={price}
              />
            </div>
          </>
        ) : (
          <>
            <div className="marketplace__content__grid__wrapper">
              <div className="marketplace__content__grid">
                {Object.values(PRODUCT_DETAILS).map(product => (
                  <ProductPlan
                    descriptions={product.descriptions}
                    details={product.details}
                    key={product.id}
                    name={product.name}
                    price={product.price}
                    imgSrc={product.imgSrc}
                    openProductPage={() => openProductPage(product)}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default MarketSpot
