import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'

import Marketplace from './components/Marketplace'
import MarketSpot from './components/MarketSpot'
import AccountLedger from './components/AccountLedger'
import PurchaseConfirmationEmailEditor from './components/PurchaseConfirmationEmailEditor'

import './App.css'

class App extends React.PureComponent {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Route
            exact
            path="/"
            render={() => (
              <div className="App">
                <Marketplace />
              </div>
            )}
          />
          <Route
            exact
            path="/marketspot"
            render={() => (
              <div className="App">
                <MarketSpot />
              </div>
            )}
          />
          <Route
            exact
            path="/accountledger"
            render={() => (
              <div className="App">
                <AccountLedger />
              </div>
            )}
          />
        </div>
        <Route
          exact
          path="/edit-confirmation-email"
          render={() => (
            <div className="App">
              <PurchaseConfirmationEmailEditor />
            </div>
          )}
        />
      </BrowserRouter>
    )
  }
}
export default App
