import React from 'react'
import { Link } from 'react-router-dom'

import io from 'socket.io-client'
import netsuite from '../assets/logos/netsuite.png'
import qbo from '../assets/logos/qbo.png'
import sage from '../assets/logos/sage.png'
import xero from '../assets/logos/xero.png'
import Transaction from './Transaction'

class AccountLedger extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      transactions: [],
    }
    this.getTransactions = this.getTransactions.bind(this)
    this.updateTransactions = this.updateTransactions.bind(this)
  }

  componentDidMount() {
    fetch('/transactions')
      .then(response => response.json())
      .then(transactions => {
        this.setState({
          transactions,
        }, this.getTransactions)
      })
  }

  getTransactions() {
    const socket = io('http://localhost:8000')
    socket.on('transactions', transaction => this.updateTransactions(transaction))
  }

  updateTransactions(transaction) {
    const { transactions } = this.state
    // TO-DO: improve hacky solution to stop multiple emits via Socket.io
    if (transactions.length === 0 || transactions[0].id !== transaction.transaction.id) {
      let reducedTransaction = transaction['transaction']
      reducedTransaction.Purchaser = transaction.Purchaser
      const updatedTransactions = [reducedTransaction].concat(transactions)
      this.setState({ transactions: updatedTransactions })
    }
  }

  render() {
    const { transactions } = this.state

    return (
      <div className="account-ledger">
        <div className="account-ledger__header">
          <span className="account-ledger__title">Account Ledger</span>
          <div className="account-ledger__integration-logo__wrapper">
            <img src={netsuite} className="account-ledger__integration-logo" alt="integration-logo" />
            <img src={qbo} className="account-ledger__integration-logo" alt="integration-logo" />
            <img src={sage} className="account-ledger__integration-logo" alt="integration-logo" />
            <img src={xero} className="account-ledger__integration-logo" alt="integration-logo" />
          </div>
        </div>
        <div className="account-ledger__header__buffer">
          <Link to="/">
            <span className="marketplace__content__product-page__back">{'< Back to Teampay Marketplace'}</span>
          </Link>
        </div>
        <div className="account-ledger__category-header">
          <span className="account-ledger__category__date">Date</span>
          <span className="account-ledger__category__text">Purchaser</span>
          <span className="account-ledger__category__text">Type</span>
          <span className="account-ledger__category__text">Description</span>
          <span className="account-ledger__category__text">Category</span>
          <span className="account-ledger__category__amount">Amount</span>
        </div>
        <div className="account-ledger__wrapper">
          <div className="transactions__wrapper">
            {transactions.length > 0 && (
              <Transaction
                amount={transactions[0].amount}
                category={transactions[0].category}
                date={transactions[0].date}
                description={transactions[0].description}
                name={transactions[0].Purchaser.name}
              />
            )}
            {transactions.slice(1).map(transaction => (
              <Transaction
                amount={transaction.amount}
                category={transaction.category}
                date={transaction.date}
                description={transaction.description}
                name={transaction.Purchaser.name}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default AccountLedger
